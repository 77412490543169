<template>
  <el-collapse-item title="Группировка">
    <el-checkbox v-model="grouping.isAdditionalSource">Дополнительный источник <br/>данных</el-checkbox>
    <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.additional_fields">
      <el-button @click="addField">
        {{$locale.interface_editor.component_editor.filters.add_filter}}
      </el-button>
      <el-row v-for="(field, index) in grouping.additionalFields" :key="index">
        <el-col :span="10">
          <el-input v-model="field.attribute"></el-input>
        </el-col>
        <el-col :span="10">
          <el-select v-model="field.type">
            <el-option
              v-for="(item, index) in $locale.interface_editor.component_editor.editor_tasks_source.additional_fields_operations"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button @click="removeField(index)"
                     size="mini"
                     type="danger"
                     :title="$locale.main.button.delete">
            <i class="el-icon-delete"></i>
          </el-button>
        </el-col>
      </el-row>
    </el-form-item>
  </el-collapse-item>
</template>

<script>
    import EditorFilters from '@/components/InterfaceEditor/components/editor/editor-filters'
    export default {
      name: "grouping-source",
      components: {
        EditorFilters
      },
      props: {
        value: {
          type: Object,
        }
      },
      data () {
        return {
          grouping: {}
        }
      },
      mounted() {
        this.grouping = this.value.grouping
      },
      watch: {
        grouping: {
          handler(value) {
            this.value.grouping = value
          },
          deep: true
        }
      },
      methods: {
        addField () {
          if (typeof this.value.additionalFields === 'undefined') {
            this.$set(this.value, 'additionalFields', [])
          }
          this.value.additionalFields.push({
            attribute: null,
            type: null
          })
        },
      }
    }
</script>

<style scoped>

</style>
